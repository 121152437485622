<template>
<main id="main" class="tender">
  <ItemSearch :parent="$data" :search="searchTenderItems" :printItemsCsv="printItemsCsv" :favorite="favorite"/>
</main>
</template>

<script>
import Methods from '@/api/methods'
import ItemSearch from '../../components/ItemList/ItemSearch'
export default {
  components : {
    ItemSearch
  },
  data() {
    return {
      nameTag        : 'TENDER_PAGE_SEARCH_HEADER',
      typeTag        : 'TENDER_PAGE_SEARCH_TYPE',
      detailPageName : this.$define.PAGE_NAMES.TENDER_DETAIL_PAGE_NAME
    }
  },
  methods : {
    searchTenderItems(params) {
      return Methods.apiExcute('public/search-auction-items', this.$i18n.locale, Object.assign({exhClass : 2}, params))
    },
    printItemsCsv(params) {
      return Methods.apiExcute('private/print-auction-items-csv', this.$i18n.locale, Object.assign({exhClass : 2}, params))
    },
    favorite(params) {
      return Methods.apiExcute('private/favorite-item', this.$i18n.locale, params)
    },
    refreshItem(params) {
      return Methods.apiExcute('public/refresh-item', this.$i18n.locale, params)
    }
  }
}
</script>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
